<template>
  <div>
    <div v-if="packageList.length > 0">
      <div class="position-relative table-container" style="padding-top: 18px;">
        <div class="table-tag">
          <table>
            <tr>
              <th class="note-col" style="padding: 0">
              </th>
              <td v-for="(item, index) in packageList" :key="item._id + '_note' + index" class="note-row"
                  style="padding: 0">
                <div class="position-relative d-flex">
                  <div v-if="currentPackageId === item._id" class="note-top-of-table current">
                    {{ $t('subscriptions.currentPackage') }}
                  </div>
                  <div v-else-if="item.is_popular || item.is_recommend" class="note-top-of-table popular">
                    <span v-if="item.is_recommend"><img src="@/assets/images/icon/star-grow.svg" alt="star-grow"> {{
                      $t('subscriptions.recommend') }}</span>
                    <span v-if="item.is_popular && item.is_recommend">{{ $t('subscriptions.and_') }}</span>
                    <span v-if="item.is_popular"><img src="@/assets/images/icon/fire.svg" alt="fire"> {{
                      $t('subscriptions.popular') }}</span>
                  </div>
                  <div v-else-if="item.is_popular" class="note-top-of-table popular">
                    <span>
                      <img src="@/assets/images/icon/fire.svg" alt="fire">
                      {{ $t('subscriptions.popular') }}
                    </span>
                  </div>
                  <div v-else-if="item.is_recommend" class="note-top-of-table recommend">
                    <span>
                      <img src="@/assets/images/icon/star-grow.svg" alt="star-grow">
                      {{ $t('subscriptions.recommend') }}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th class="head-col top-row">
                <div class="position-relative flex-center font-size-16" style="height: 100%; color: #fff;">
                  {{ $t('subscriptions.planName') }}
                </div>
              </th>
              <td v-for="(item, index) in packageList" :key="item._id + '_name' + index" class="top-row">
                <div class="">
                  <div class="text-center font-size-16 font-weight-600">
                    {{ item.name }}
                  </div>
                  <div class="text-center font-size-24 font-weight-500">
                    <div class="font-size-24">
                      {{ checkPricePlan(item.price, item.price_discount, item.currency, item.type).sum_discount }}
                    </div>
                    <div class="price-decoration" v-if="item.price_discount > 0">
                      {{ checkPricePlan(item.price, item.price_discount, item.currency, item.type).price }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="checkPermissionBrand('master-sender-names')">
              <th class="head-col normal-col">
                {{ $t('subscriptions.allowed_public_sender_name') }}
                <span class="bg-primary new-banner">New</span>
              </th>
              <td v-for="(item, index) in packageList" :key="item._id + '_sender' + index" class="normal-col"
                  :class="`${getBackgroundColor(item._id, item.discount_price, item.is_popular, item.is_recommend)}`">
                <div class="text-center">
                  <img v-if="!item.options.random_sender" src="@/assets/images/icon/close-mark.svg" alt="success">
                  <img v-else src="@/assets/images/icon/check-mark.svg" alt="success">
                </div>
              </td>
            </tr>
            <tr>
              <th class="head-col normal-col">{{ $t('subscriptions.package.customerCanUseApi') }}</th>
              <td v-for="(item, index) in packageList" :key="item._id + '_api' + index" class="normal-col"
                  :class="`${getBackgroundColor(item._id, item.discount_price, item.is_popular, item.is_recommend)}`">
                <div class="text-center">
                  <img v-if="!item.options.api_access" src="@/assets/images/icon/close-mark.svg" alt="success">
                  <img v-else src="@/assets/images/icon/check-mark.svg" alt="success">
                </div>
              </td>
            </tr>
            <tr>
              <th class="head-col normal-col">{{ $t('subscriptions.package.needSenderNameVerification') }}</th>
              <td v-for="(item, index) in packageList" :key="item._id + '_get_sender' + index" class="normal-col"
                  :class="`${getBackgroundColor(item._id, item.discount_price, item.is_popular, item.is_recommend)}`">
                <div class="text-center">
                  <img v-if="!item.options.sender_id_verification" src="@/assets/images/icon/close-mark.svg"
                       alt="success">
                  <img v-else src="@/assets/images/icon/check-mark.svg" alt="success">
                </div>
              </td>
            </tr>
            <tr>
              <th class="head-col normal-col">{{
                $t('subscriptions.package.addPreviousSmsUnitsOnNextSubscription')
                }}
              </th>
              <td v-for="(item, index) in packageList" :key="item._id + '_credit_from' + index" class="normal-col"
                  :class="`${getBackgroundColor(item._id, item.discount_price, item.is_popular, item.is_recommend)}`">
                <div class="text-center">
                  <img v-if="!item.options.add_previous_balance" src="@/assets/images/icon/close-mark.svg"
                       alt="success">
                  <img v-else src="@/assets/images/icon/check-mark.svg" alt="success">
                </div>
              </td>
            </tr>
            <tr>
              <th class="head-col normal-col">{{ $t('contacts.groupList.max_contact_list') }}</th>
              <td v-for="(item, index) in packageList" :key="item._id + '_maximum_con' + index" class="normal-col"
                  :class="`${getBackgroundColor(item._id, item.discount_price, item.is_popular, item.is_recommend)}`">
                <div class="text-center">
                  {{
                  item.options.max_contact_list === -1 ? $t('common.Unlimited') :
                  nFormatter(item.options.max_contact_list)
                  }}
                </div>
              </td>
            </tr>
            <tr>
              <th class="head-col normal-col">{{ $t('contacts.groupList.max_contact_per_list') }}</th>
              <td v-for="(item, index) in packageList" :key="item._id + '_maximum_group' + index" class="normal-col"
                  :class="`${getBackgroundColor(item._id, item.discount_price, item.is_popular, item.is_recommend)}`">
                <div class="text-center">
                  {{
                  item.options.max_contact_per_list === -1 ? $t('common.Unlimited') :
                  nFormatter(item.options.max_contact_per_list)
                  }}
                </div>
              </td>
            </tr>
            <tr>
              <th class="head-col normal-col">{{ $t('subscriptions.creditAdding') }}</th>
              <td v-for="(item, index) in packageList" :key="item._id + '_credit_top' + index" class="normal-col"
                  :class="`${getBackgroundColor(item._id, item.discount_price, item.is_popular, item.is_recommend)}`">
                <div class="text-center">
                  <span class="">{{
                    item.options.sms_max === -1 ? $t('common.Unlimited') : item.options.sms_max.toLocaleString()
                  }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <th class="head-col normal-col">{{ $t('subscriptions.price') }} / {{ $t('subscriptions.list.message') }}
              </th>
              <td v-for="(item, index) in packageList" :key="item._id + '_price_per' + index" class="normal-col"
                  :class="`${getBackgroundColor(item._id, item.discount_price, item.is_popular, item.is_recommend)}`">
                <div class="text-center">
                  <span>{{
                    item.options.per_unit_price ? item.options.per_unit_price.toFixed(2) : 0
                  }}</span>
                </div>
              </td>
            </tr>
            <!--            <tr>-->
            <!--              <th class="head-col normal-col">{{ $t('subscriptions.package.URLTracking') }}</th>-->
            <!--              <td v-for="(item, index) in packageList" :key="item._id + '_url' + index" class="normal-col"-->
              <!--                :class="`${getBackgroundColor(item._id, item.discount_price, item.is_popular, item.is_recommend)}`">-->
            <!--                <div class="text-center">-->
            <!--                  {{ item.options.url_tracking ? item.options.url_tracking.toLocaleString() : '-' }}-->
            <!--                </div>-->
            <!--              </td>-->
            <!--            </tr>-->
            <tr v-if="checkPermissionBrand('url-tracking-premium')">
              <th class="head-col normal-col">
                {{ $t('subscriptions.package.upgrade_package_tracking2pro') }}
              </th>
              <td v-for="(item, index) in packageList" :key="item._id + '_tracking_2_pro' + index" class="normal-col"
                  :class="`${getBackgroundColor(item._id, item.discount_price, item.is_popular, item.is_recommend)}`">
                <div class="text-center">
                  <img v-if="!item.premium_url_plan_id" src="@/assets/images/icon/close-mark.svg"
                       alt="success">
                  <img v-else src="@/assets/images/icon/check-mark.svg" alt="success">
                </div>
              </td>
            </tr>
            <tr>
              <th class="head-col normal-col">{{ $t('subscription.checkout.frequency') }}</th>
              <td v-for="(item, index) in packageList" :key="item._id + '_expire_' + index" class="normal-col"
                  :class="`${getBackgroundColor(item._id, item.discount_price, item.is_popular, item.is_recommend)}`">
                <div class="text-center">
                  {{ $t('common.serviceLife') }}
                  <span class="text-primary"> {{
                    item.type === 'trial' ? ' 14 ' : (item.year_cycle ? item.year_cycle :
                      $t('common.Unlimited'))
                  }} </span>
                  {{ item.type === 'trial' ? $t('common.day') : item.year_cycle ? $t('common.month') : '' }}
                </div>
              </td>
            </tr>
            <tr>
              <th class="head-col header-last-col">{{ $t('subscriptions.subscriptionsNow') }}</th>
              <td v-for="(item, index) in packageList" :key="item._id + '_get_' + index" class="last-col">
                <div class="flex-middle">
                  <b-button size="lg" pill variant="gradient" :disabled="is_loading" @click="checkout(item, true)"
                            style="min-width: 140px;">
                    {{ $t("subscriptions.subscriptionsNow").toUpperCase() }}
                    <b-spinner v-show="item._id === loading_id" small v-if="is_loading"/>
                  </b-button>
                  <!--                  <b-button v-else :disabled="currentPackageId === item._id" size="lg" :disabled="currentPackageId === item._id" pill variant="outline"
                                              @click="renewPackage(item)"-->
                  <!--                            style="min-width: 140px;">-->
                  <!--                    {{ $t("invoice.renew").toUpperCase() }}-->
                  <!--                  </b-button>-->
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="is_active_custom">
        <custom-package/>
      </div>
    </div>
    <div v-else-if="!!is_loading_package_list" class="text-center text-primary my-2"
         style="position: absolute;transform: translate(-50%, -50%); left: 50%;top: 50%;">
      <b-spinner style="width: 3rem; height: 3rem" class="align-middle mr-2"/>
      <strong> {{ $t('loading') }}</strong>
    </div>
    <div v-else>
      <empty-table content="empty" is-empty-in-full-page/>
    </div>
    <modal-tutorial/>

  </div>
</template>

<script>
import TopicComponent from "@/components/common/topicComponent";
import packageApi from "@/repository/packageApi";
import subscriptionApi from "@/repository/subscriptionApi";
import alert from "@/common/alert";
import EmptyTable from "@/components/common/emptyTable.vue";
import CustomPackage from "@/views/subscriptions/customPackage.vue";
import functions from "@/common/functions";
import brandApi from "@/repository/brandApi";
import ModalTutorial from "@/views/subscriptions/modalTutorial.vue";
import functionsCommon from "@/common/functions";

export default {
  name: "subscription",
  components: {
    ModalTutorial,
    CustomPackage,
    EmptyTable,
    TopicComponent,
  },
  data() {
    return {
      is_loading: false,
      packageList: [],
      currentPackageId: '',
      loading_id: '',
      is_loading_package_list: false,
      is_active_custom: false,
      loopMax: 0,
      permissionBrand: []
    }
  },
  computed: {
    userInfo () {
      return  this.$store.getters["profile/getProfile"]
    }
  },
  mounted() {
    this.currentPackageId = this.$route.query.current
    this.checkingBrandID();
    this.getPermissionBrand();
    if (this.userInfo?.identity_verify_status !== 'verified' && !this.getCookie('ACCEPT_TUTORIAL') && !this.currentPackageId) {
      this.$bvModal.show('modal-tutorial')
    }
  },
  destroyed() {
    this.removeCookieCustomPrice('CUSTOM_PRICE')
  },
  methods: {
    checkout(packageData, manual) {
      this.loading_id = packageData?._id
      this.is_loading = true
      sessionStorage.setItem('_pack', JSON.stringify(packageData))
      if (packageData.type === 'trial') {
        const mapData = {
          plan_id: packageData?._id,
          bank_account_id: null,
          discount_code: null
        }
        subscriptionApi.createSubscription(mapData).then(resp => {
          if (resp) {
            alert.addNewSuccess(this)
            this.$router.push(`/Subscriptions`).catch(() => {
            })
          }
        }).catch(err => {
          alert.addNewFailed(this, err.response.data.message)
        }).finally(() => {
          this.is_loading = false
        })
      } else {
        const channel = this.$route.query.channel || '*'
        if (manual) {
          this.$router.push(`/Subscriptions/Checkout`).catch(() => {
          })
        } else {
          this.$router.push(`/Subscriptions/Checkout` + (channel ? `?channel=${channel}` : '')).catch(() => {
          })
        }
        this.is_loading = true
      }
    },
    renewPackage(packageDetail) {
      sessionStorage.setItem('_pack', JSON.stringify(packageDetail))
      this.$router.push(`/Subscriptions/Checkout?subscription=renew`).catch(() => {
      })
    },
    nFormatter(num) {
      return Math.abs(num) > 999999 ? (Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1))).toLocaleString() + 'M' : (Math.sign(num) * Math.abs(num)).toLocaleString()
    },
    finePackageAll() {
      this.is_loading_package_list = true
      packageApi.findPackageAll().then(resp => {
        this.packageList = resp?.data;
      }).catch(err => {
        throw err
      }).finally(() => {
        this.is_loading_package_list = false
        const package_id = this.$route.query.package_id;
        const go_to = this.$route.query.go_to;
        if (package_id && this.packageList.length > 0 && go_to === 'checkout') {
          // Map package id with package list
          const packageData = this.packageList.find(item => item._id === package_id)
          if (packageData) {
            this.checkout(packageData)
          } else {
            alert.fail(this, this.$t('common.no_data_table'))
          }
        }
      })
    },
    checkPricePlan(price, discount_price, currency, type) {
      let is_price = ''
      let is_discount = ''
      if (type === 'trial') {
        is_price = 'Free trial'
        is_discount = 'Free trial'
      } else if (price && currency) {
        const priceFormat = currency.format ? currency?.format : ""
        const sum = (Number(price || 0) - Number(discount_price || 0))
        is_price = priceFormat.replace('{PRICE}', price?.toLocaleString() || 0) || '';
        is_discount = priceFormat.replace('{PRICE}', sum?.toLocaleString() || 0) || '';
      }
      return {
        price: is_price || price,
        sum_discount: is_discount
      }
    },
    monthFormat(month) {
      const years = Math.floor(month / 12);
      month = month % 12;
      return `${years ? years + ` ${this.$t('common.year')}` : ''} ${month ? month + ` ${this.$t('common.month')}` : ''}`;
    },
    getBackgroundColor(id, discount_price, is_popular, is_recommend) {
      if (this.currentPackageId === id) {
        return "current-td";
        // } else if (discount_price > 0) {
        //   return "sale-td";
      } else if (is_popular) {
        return "popular-td";
      } else if (is_recommend) {
        return "recommend-td";
      }
      return "r"
    },
    checkingBrandID() {
      const brandId = sessionStorage.getItem("brand_id") ? sessionStorage.getItem("brand_id") : "";
      if (!brandId && this.loopMax < 10) {
        setTimeout(() => {
          this.checkingBrandID()
          this.loopMax++
        }, 250)
      } else {
        this.finePackageAll()
        this.getActiveCustomPlan()
      }
    },
    getActiveCustomPlan() {
      packageApi.getActiveCustomPlan().then(resp => {
        this.is_active_custom = resp?.active_custom_plan
      }).catch(err => {
        throw err
      })
    },
    removeCookieCustomPrice(name) {
      return functions.deleteCookie(name)
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    getPermissionBrand() {
      brandApi.getBrandPermissions().then(res => {
        this.permissionBrand = res.data
      })
    },
    checkPermissionBrand(permission) {
      return functions.brandCheckPermission(permission, this.permissionBrand)
    },
    getCookie (name) {
      return functionsCommon?.getCookie(name)
    }
  }
}
</script>

<style scoped lang="scss">

@keyframes new-star {
  0% {
    opacity: 1;
  }

  10% {
    opacity: 0.6;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }

}

.new-banner {
  background-color: var(--primary-color);
  border-radius: 3px;
  color: #fff;
  padding: 0 4px;
  font-size: 12px;
  animation: new-star 2.4s infinite;
}

.shadow-package {
  box-shadow: rgba(0, 0, 0, 0.01) 0px 2px 5px
}

.shadow-package-active {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px, 0px -10px 0px var(--primary-color);
  border: 1.5px solid var(--secondary-color) !important;
}

.price {
  border-radius: 20px;
  background-color: #F5F7FB;
  padding-top: 1px;
  padding-bottom: 1px;
}

.display-2 {
  font-size: 1.25rem;
}

.price-decoration {
  text-decoration: line-through;
  font-size: 12px;
}

// copy
.table-container {
  border-radius: 10px;
  font-weight: 500;
  margin-bottom: 24px;
}

.table-tag {
  $table-padding: 18px;
  $header-width: 350px;
  $header-height: 100px;

  width: calc(100% - $header-width);
  overflow-x: scroll;
  margin-left: $header-width;
  overflow-y: visible;
  padding: 0;

  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }

  td,
  th {
    margin: 0;
    white-space: nowrap;
    border: 1px solid #E9E9E9;
    border-width: 0 1px 1px 0;
    font-size: 14px;
    font-weight: 500;
  }

  th {
    padding-right: calc($table-padding / 2);
    padding: $table-padding 24px;
  }

  td {
    padding: $table-padding;
  }

  .head-col {
    position: absolute;
    width: $header-width;
    left: 0;
    top: auto;

    // text-ellipsis
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .normal-col {
    background-color: #fff;
    min-height: 18px;

    img {
      height: 21px;
    }
  }

  .top-row {
    background-color: var(--primary-color);
    color: white;
    height: $header-height;
    padding: calc($table-padding / 3) $table-padding;

    &:first-child {
      border-radius: 10px 0px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 10px 0px 0px;
    }
  }

  .td-cell {
    color: #2F2E2E;
    letter-spacing: 1em;
  }

  .note-col,
  .note-row {
    background-color: transparent;
    padding: 0;
    border-color: transparent;
  }

  .last-col .head-col {
    background-color: #fff;
    height: 96px;
  }

  .last-col {
    border-color: transparent;
    background-color: #fff;
    height: 96px;

    &:last-child {
      border-radius: 0px 0px 10px 0px;
    }
  }

  .header-last-col {
    background-color: #fff;
    border-bottom-color: transparent;
    height: 96px;
    border-radius: 0px 0px 0px 10px;
    align-items: center;
    display: flex;
  }

  .note-top-of-table {
    font-size: 16px;
    font-weight: 700;
    background-color: #F5E7FF;
    border-radius: 10px 10px 0px 0px;
    color: #5C4CAE;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 4px);
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    box-shadow: 0px 2px 20px 0px rgba(92, 76, 174, 0.20);

    img {
      margin-right: 4px;
    }
  }

  .note-top-of-table.current {
    background-color: rgba(var(--secondary-color-rgb), 0.1);
    color: var(--primary-color);
  }

  .note-top-of-table.popular {
    background-color: #FFDEDE;
    color: #DE0000;
  }

  .popular-td {
    background-color: #FFF3F3;
  }

  .note-top-of-table.recommend {
    background-color: #FFEADE;
    color: #FF4D00;
  }

  .recommend-td {
    background-color: #FFF3EC;
  }

  @media screen and (max-width: 700px) {
    width: calc(100% - 220px);
    overflow-x: scroll;
    margin-left: 220px;

    .head-col {
      width: 220px;
    }
  }

  @media screen and (max-width: 500px) {
    width: calc(100% - 160px);
    overflow-x: scroll;
    margin-left: 160px;

    .head-col {
      width: 160px;
    }
  }
}

.load-content-card {
  min-height: calc(100vh - 200px);
}
</style>