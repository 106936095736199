<template>
  <div class="custom-section" id="custom_package_component">
    <b-row align-h="center">
      <b-col align="center">
        <h4>
          <span class="pr-3">
            <img src="@/assets/images/icon/message-noti.svg" alt="message-noti">
          </span>
          {{ $t('subscriptions.customPackage.fillnumberOfCredit') }}
        </h4>
      </b-col>
    </b-row>
    <div :class="{'flex-middle': innerWidth > mobileWidth}">
      <div :style="innerWidth > mobileWidth ? 'min-width: 360px;' : ''">
        <h6>{{$t('subscriptions.customPackage.amount')}}</h6>
        <b-row class="mt-1 mb-0" align-h="center">
          <b-col sm="12" md="12" lg="9" xl="9" align="right">
            <input-component v-model="input" :placeholder="$t('subscriptions.customPackage.PleaseFill')"
                             :disabledType="!selectedFormula"
                             :state="false ? $t('common.requiredField') : ''" minHeight="2em" required
                             @keypress="chkNumber" @input="formatInput"/>
          </b-col>
          <b-col sm="12" md="12" lg="3" xl="3">
            <b-button variant="primary" :disabled="!selectedFormula || !input" class="ml-1"
                      :class="{'float-right m-0 text-center': innerWidth <= mobileWidth}" @click="calculate">
             <span>
                {{ $t('subscriptions.customPackage.calulate') }}
              </span>
              <b-spinner class="ml-2" v-if="calculate_loading" small/>
            </b-button>
          </b-col>
        </b-row>
        <small v-if="message_error" class="m-0 p-0 text-danger text-left" style="max-width: 360px;">
          {{ message_error.split(": ")[1] }}
        </small>
        <div class="text-center mt-3" v-if="message_error">
          <div class="text-danger error-range">
            {{ $t('subscriptions.customPackage.range_not_found') }}
          </div>
          <div>
            {{ $t('subscriptions.customPackage.contact_admin') }}
          </div>
        </div>
      </div>
    </div>
    <div :class="[{'flex-middle': innerWidth > mobileWidth}, 'mt-2']" v-if="calculateStatus">
      <div v-if="calculateStatus && !message_error" :style="innerWidth > mobileWidth ? 'min-width: 360px;' : ''">
        <b-row v-if="selectedFormula === 'message'">
          <b-col>
            <h4>
              {{ $t('subscriptions.customPackage.amount') }}
            </h4>
          </b-col>
          <b-col align="right">
            <h4 class="text-primary">
              {{ info.price.toLocaleString() }} {{ $t('subscriptions.customPackage.baht') }}
            </h4>
          </b-col>
        </b-row>
        <b-row v-else-if="selectedFormula === 'price'">
          <b-col sm="12" md="12" lg="5" xl="5">
            <h4>
              {{ $t('subscriptions.customPackage.numberOfCredit') }}
            </h4>
          </b-col>
          <b-col sm="12" md="12" lg="7" xl="7" :align="innerWidth <= mobileWidth ? 'center' : 'right'">
            <h4 class="text-primary">
              {{ info.credit.toLocaleString() }} {{ $t('subscriptions.customPackage.credit') }}
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            {{ $t('subscriptions.customPackage.price_or_message') }}
          </b-col>
          <b-col align="right">
            {{
            info.cost_per_credit ? info.cost_per_credit.toFixed(2) : 0
            }} {{ $t('subscriptions.customPackage.message') }}
          </b-col>
        </b-row>
        <b-row>
          <b-col align="center">
            <b-button variant="primary" class="mt-2 mr-2" @click="seeDetails">
              {{ $t('subscriptions.customPackage.seeDetail') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal v-model="modalShow" hide-footer no-close-on-esc no-close-on-backdrop centered>
      <template #modal-header="{ close }">
        <h5>{{$t('subscriptions.customPackage.detailOfCustomPackage')}}</h5>
        <i class="fas fa-times hover-icon action-button" @click="closePopup()"></i>
      </template>
      <div class="mr-3 ml-3 mb-3">
        <input-component v-model="packageDetails.name" :labelInput="$t('subscriptions.planName')" disabledType/>

        <b-row v-if="checkPermissionBrand('master-sender-names')" class="row-custom-modal mt-4">
          <b-col>
            {{ $t('subscriptions.allowed_public_sender_name') }}
          </b-col>
          <b-col align="right">
            <img v-if="!packageDetails.features.random_sender" src="@/assets/images/icon/close-mark.svg" alt="success">
            <img v-else src="@/assets/images/icon/check-mark.svg" alt="success">
          </b-col>
        </b-row>
        <b-row class="row-custom-modal">
          <b-col>
            {{ $t('subscriptions.package.customerCanUseApi') }}
          </b-col>
          <b-col align="right">
            <img v-if="!packageDetails.features.using_api" src="@/assets/images/icon/close-mark.svg" alt="success">
            <img v-else src="@/assets/images/icon/check-mark.svg" alt="success">
          </b-col>
        </b-row>
        <b-row class="row-custom-modal">
          <b-col>
            {{ $t('subscriptions.package.needSenderNameVerification') }}
          </b-col>
          <b-col align="right">
            <img v-if="!packageDetails.features.request_sender" src="@/assets/images/icon/close-mark.svg" alt="success">
            <img v-else src="@/assets/images/icon/check-mark.svg" alt="success">
          </b-col>
        </b-row>
        <b-row class="row-custom-modal">
          <b-col cols="8">
            {{ $t('subscriptions.package.addPreviousSmsUnitsOnNextSubscription') }}
          </b-col>
          <b-col align="right">
            <img v-if="!packageDetails.features.compound_credit" src="@/assets/images/icon/close-mark.svg"
                 alt="success">
            <img v-else src="@/assets/images/icon/check-mark.svg" alt="success">
          </b-col>
        </b-row>
        <b-row class="row-custom-modal">
          <b-col>
            {{ $t('contacts.groupList.max_contact_list') }}
          </b-col>
          <b-col align="right">
            {{ unlimitedFormatter(packageDetails.features.max_contact_group) }}
          </b-col>
        </b-row>
        <b-row class="row-custom-modal">
          <b-col>
            {{ $t('contacts.groupList.max_contact_per_list') }}
          </b-col>
          <b-col align="right">
            {{ unlimitedFormatter(packageDetails.features.max_contact) }}
          </b-col>
        </b-row>
        <b-row class="row-custom-modal">
          <b-col>
            {{ $t('subscriptions.creditAdding') }}
          </b-col>
          <b-col align="right">
            {{
            packageDetails.credit ? packageDetails.credit.toLocaleString() : 0
            }}
          </b-col>
        </b-row>
        <b-row class="row-custom-modal">
          <b-col>
            {{ $t('subscriptions.price') }} / {{ $t('subscriptions.list.message') }}
          </b-col>
          <b-col align="right">
            {{ packageDetails.cost_per_credit }}
          </b-col>
        </b-row>
        <b-row v-if="checkPermissionBrand('url-tracking-premium')" class="row-custom-modal">
          <b-col>
            {{ $t('subscriptions.package.upgrade_package_tracking2pro') }}
          </b-col>
          <b-col align="right">
            <img v-if="!packageDetails.premium_url_plan_id" src="@/assets/images/icon/close-mark.svg" alt="no">
            <img v-else src="@/assets/images/icon/check-mark.svg" alt="yes">
          </b-col>
        </b-row>
        <b-row class="row-custom-modal">
          <b-col>
            {{ $t('subscription.checkout.frequency') }}
          </b-col>
          <b-col align="right">
            <span class="font-weight-700 text-primary">
              {{ packageDetails.features.month_cycle.toLocaleString() }}
            </span>
            {{ $t('common.month') }}
          </b-col>
        </b-row>
        <b-row>
          <b-col align="right">
            <b-button variant="primary" class="mt-2 mb-1" @click="confirmPackage">
              {{ $t('subscriptions.customPackage.confirmCreate') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import alert from "@/common/alert"
import InputComponent from '@/components/common/inputComponent'
import packageApi from "@/repository/packageApi";
import functions from "@/common/functions";
import {mapState} from "vuex";
import brandApi from "@/repository/brandApi";

export default {
  name: "customPackage",
  components: {
    InputComponent
  },
  watch: {
    selectedFormula: function (val) {
      if (val) {
        this.calculateStatus = false
        this.message_error = ''
      }
    }
  },
  data() {
    return {
      selectedFormula: "price",
      input: "",
      calculateStatus: false,
      calculate_loading: false,
      modalShow: false,
      message_error: "",
      info: {
        price: 0,
        credit: 0,
        cost_per_credit: 0,
      },
      packageDetails: {
        _id: "",
        custom_plan_id: "",
        name: "",
        price: 0,
        credit: 0,
        codeSYS: "",
        cost_per_credit: 0,
        features: {
          compound_credit: false,
          max_contact: 0,
          max_contact_group: "-",
          month_cycle: 0,
          request_sender: false,
          url_tracking: 0,
          using_api: false
        },
        premium_url_plan_net: 0,
        premium_url_plan_price: 0,
        premium_url_plan_discount_percentage: 0
      },
      is_calculate: false,
      permissionBrand: []
    }
  },
  computed: {
    ...mapState({
      innerWidth: state => state.theme.width_inner,
      mobileWidth: state => state.theme.mobile_width
    })
  },
  mounted() {
    // query string `/Subscriptions/Package?${this.selected === 'credit' ? 'credit' : 'price'}=${value}`
    const query = this.$route.query
    setTimeout(() => {
      if (query.credit) {
        this.scrollToHighLight()
        this.selectedFormula = "message"
        this.input = query.credit
        this.calculate()
      } else if (query.price) {
        this.scrollToHighLight()
        this.selectedFormula = "price"
        this.input = query.price
        this.calculate()
      }
      this.getPermissionBrand()
    }, 1000)
  },
  methods: {
    scrollToHighLight() {
      const target = document.querySelector('#custom_package_component')
      if (!_.isNull(target)) {
        target.scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
      }
    },
    nFormatter(num) {
      return Math.abs(num) > 999999 ? (Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1))).toLocaleString() + 'M' : (Math.sign(num) * Math.abs(num)).toLocaleString()
    },
    chkNumber(e) {
      return functions.checkNumber(e)
    },
    calculate() {
      this.calculate_loading = true;
      this.calculateStatus = false;
      const data = {
        price: "",
      }
      const price = this.input.replace(/\D/g, '');
      if (this.selectedFormula === "message") {
        data.credit = parseInt(price)
      } else if (this.selectedFormula === "price") {
        data.price = parseInt(price)
      } else {
        return;
      }
      setTimeout(() => {
        this.clearData();
      }, 0);
      try {
        packageApi.customPlanDetail(data).then((res) => {
          this.info = {
            price: res.price,
            credit: res.credit,
            cost_per_credit: res.cost_per_credit,
          };
          this.packageDetails = res;
          this.packageDetails.name = res.name;
        }).catch((err) => {
          this.message_error = err?.response?.data?.message || "";
        }).finally(() => {
          this.calculateStatus = true;
          this.calculate_loading = false;
        })
      } catch (error) {
        console.warn(error)
      }
    },
    seeDetails() {
      this.modalShow = true;
    },
    confirmPackage() {
      const map_data = {
        _id: this.packageDetails.custom_plan_id,
        name: this.packageDetails.name,
        price: this.packageDetails.price,
        package_credit: this.packageDetails.credit,
        total_url: this.packageDetails?.features?.url_tracking,
        premium_url_plan_id: this.packageDetails.premium_url_plan_id,
        premium_url_plan_net: this.packageDetails?.premium_url_plan_net,
        premium_url_plan_price: this.packageDetails?.premium_url_plan_price,
        premium_url_plan_discount_percentage: this.packageDetails?.premium_url_plan_discount_percentage

      }
      sessionStorage.setItem('_pack', JSON.stringify(map_data))
      this.$router.push(`/Subscriptions/Checkout?subscription=custom_package`).catch(() => {
      })
    },
    clearData() {
      this.message_error = "";
      this.info = {
        price: 0,
        credit: 0,
        cost_per_credit: 0,
      }
      this.packageDetails = {
        _id: "",
        custom_plan_id: "",
        name: "",
        price: 0,
        codeSYS: "",
        cost_per_credit: 0,
        credit: 0,
        features: {
          compound_credit: false,
          max_contact: 0,
          max_contact_group: "-",
          month_cycle: 0,
          request_sender: false,
          url_tracking: 0,
          using_api: false
        },
        premium_url_plan_id: 0,
        premium_url_plan_net: 0,
        premium_url_plan_price: 0,
        premium_url_plan_discount_percentage: 0
      }
    },
    unlimitedFormatter(value) {
      if (typeof value === "string" && value === "unlimited") {
        return this.$t('common.Unlimited')
      } else if (typeof value === "number") {
        return value.toLocaleString()
      } else {
        return value
      }
    },
    closePopup() {
      this.clearData()
      this.modalShow = false
      this.calculateStatus = false
    },
    formatInput() {
      if (!_.isEmpty(this.input)) {
        const inputValue = this.input.replace(/,/g, '');
        let text = parseFloat(inputValue);
        this.input = text.toLocaleString();
      }
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    getPermissionBrand() {
      brandApi.getBrandPermissions().then(res => {
        this.permissionBrand = res.data
      })
    },
    checkPermissionBrand(permission) {
      return functions.brandCheckPermission(permission, this.permissionBrand)
    }
  },
};
</script>
<style lang="scss" scoped>
.custom-section {
  background-color: #fff;
  border-radius: 10px;
  padding: 32px;
  margin-top: 24px;
}

.row-custom-modal {
  margin-bottom: 1.25rem;
}

.error-range {
  font-weight: 700;
}

.new-banner {
  background-color: var(--primary-color);
  border-radius: 3px;
  color: #fff;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 500;
}
</style>