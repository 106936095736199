<template>
  <div role="group">
    <div class="form-group" :style="`min-height: ${minHeight};`">
      <b-row v-if="labelInput">
        <b-col class="col-auto" style="padding-right: 5px!important;">
          <label class="label-for-input text-secondary" :class="{'landing-text':landingPage}">{{ labelInput }} </label>
          <small class="label-for-input text-info ml-1" style="font-size: 12px;" v-if="labelInfo">{{
            labelInfo
            }} </small>
        </b-col>
        <b-col v-show="required" align="left" style="padding-left: 5px!important;">
          <strong v-show="required" style="color: red">*</strong>
          <label style="font-size: 10px" class=" text-danger ml-2" v-if="requireDetail">{{ requireDetail }} </label>
        </b-col>
      </b-row>
      <b-form-input :class="`input-text-box ${className} ${error} ${(icon === 'password' ? 'pr-5' : '')} `"
                    :id="inputId"
                    :state="state"
                    :type="isShowPass === false ? type : 'text'"
                    :placeholder="placeholder"
                    :autocomplete="type === 'password' || type !== 'text' ? 'new-password' : 'off'"
                    :value="value"
                    :style="componentStyle "
                    @input="updateValue"
                    @keypress="keypress"
                    @keyup="keyup"
                    @focusout="focusout"
                    :maxlength="max"
                    :min="minDate"
                    :max="maxDate"
                    :size="size"
                    :disabled="disabledType"
      />
      <b-input-group-append v-if="isLoading" style="    position: absolute; top: 70%; right: 12px;">
        <b-spinner class="text-primary" style="width: 18px; height: 18px"></b-spinner>
      </b-input-group-append>
      <b-img v-show="icon==='password'&&isShowPass===false" @click="showPassword" class="icon-show"
             src="~@/assets/images/icon/Eye-off.svg"/>
      <b-img v-show="icon==='password'&&isShowPass===true" @click="showPassword" class="icon-show-off "
             src="~@/assets/images/icon/Show.svg"/>
      <span v-if="showCount" class="input-count">{{ value.length }}/{{ max }}</span>
    </div>
    <div v-if="requireLabel" class="text-left">
      <div :style="isLoginForm ? `color: #FC6C6B; margin-bottom: 1rem;margin-top: -2rem;font-size: 14px;` : 'color: #FC6C6B; margin-bottom: 1px;margin-top: 0px;font-size: 14px;' ">{{ requireLabel }}</div>
    </div>
  </div>
</template>
<script>
import {parseInt} from "lodash";
import functionsCommon from "@/common/functions";
import tr from "vue2-datepicker/locale/es/tr";

export default {
  name: "inputComponent",
  computed: {
    tr() {
      return tr
    }
  },
  props: {
    landingPage: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      request: false,
      default: false
    },
    labelInfo: {
      type: String,
      request: false
    },
    componentStyle: {
      type: String,
      request: false
    },
    showCount: {
      type: Boolean,
      request: false,
      default: false
    },
    requireLabel: {
      type: String,
      request: false
    },
    requireDetail: {
      type: String,
      request: false
    },
    max: {
      type: String,
      request: false
    },
    className: {
      type: String,
      request: false
    },
    error: {
      type: String,
      request: false
    },
    type: {
      type: String,
      request: false
    },
    state: {
      type: [String, Boolean],
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      request: false
    },
    inputId: {
      type: String,
      request: false
    },
    labelInput: {
      type: String,
      request: false
    },
    disabledType: {
      type: Boolean,
      request: false,
      default: false
    },
    bgColor: {
      type: String,
      request: false
    },
    value: {
      type: [String, Number, Date, Boolean],
      request: false,
      default: ''
    },
    icon: {
      type: String,
      request: false
    },
    required: {
      type: Boolean,
      request: false,
      default: false
    },
    minDate: {
      type: [Date, String, Number],
      require: false
    },
    maxDate: {
      type: [Date, String, Number],
      require: false
    },
    minHeight: {
      type: String,
      request: false,
      default: '4em'
    },
    size: {
      type: String,
      request: false,
      default: ''
    },
    onlyTHEnglish: {
      type: Boolean,
      request: false,
      default: false
    },
    isLoginForm: {
      type: Boolean,
      request: false,
      default: false
    }
  },

  data() {
    return {
      formatted: '',
      selected: '',
      isShowPass: false
    }
  },
  methods: {
    updateValue(e) {
      const new_data = e.trim()
      const data = (this.type === 'number') && this.step !== 'any' ? parseInt(new_data) : new_data
      this.$emit('input', data)
    },
    showPassword() {
      this.isShowPass = !this.isShowPass
    },
    deleteEmoji(data) {
      return functionsCommon.removeEmoji(data)
    },
    keypress(event) {
      this.$emit('keypress', event)
    },
    keyup(event) {
      this.$emit('keyup', event)
    },
    focusout(event) {
      this.$emit('focusout', event)
    },
  },
  watch: {
    'value'(newVal, oldVal) {
      const isFalse = functionsCommon.isStringOnlyTHEnglish(newVal)
      if (!isFalse && this.onlyTHEnglish) {
        this.$emit('input', functionsCommon.deleteIsNotTHEnglish(newVal));
      } else if (!_.isNil(newVal) && !_.isEmpty(newVal)) {
        this.$emit('input', this.deleteEmoji(newVal))
      }
    }
  }
}
</script>

<style scoped>
.landing-text {
  color: #525252 !important;
}

.input-text-box {
  position: absolute;
  background: #FFFFFF;
  /*border: 1px solid #D5DFE9;*/
  box-sizing: border-box;
  border-radius: 8px;
  color: #586A8F !important;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: normal;
}

.form-control {
  font-family: "Prompt";
  font-size: 0.875rem;
  color: #586A8F !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.label-for-input {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 0;
}

.icon-show {
  position: absolute;
  right: 2px;
  /*top: 30px;*/
  cursor: pointer;
  padding: 7px;
  min-width: 10px;
  width: 33px;
}

.icon-show-off {
  position: absolute;
  right: 3px;
  /* top: 30px; */
  cursor: pointer;
  padding: 6px;
  min-width: 10px;
  margin-top: 5px;
  width: 29px;
}

.input-disable-gray {
  background: #F8F8F8 !important;
  border: 0;
}

.error {
  border: 1px solid #FC6C6B !important;
}

.input-disable-blue {
  background: #EFF6FF !important;
  border: 1px solid #EFF6FF;
}

.input-count {
  position: absolute;
  right: 50px;
  margin: -30px;
  opacity: 0.8;
  font-size: 12px;
  color: #94959B;
}

.form-group {
  position: relative;
}

.form-control.is-valid, .was-validated .form-control:valid, .form-control:focus {
  border-color: #ced4da;
  padding-right: 12px;
  background-image: none;
}

.form-control::-webkit-input-placeholder {
  color: rgba(180, 180, 185, 0.98);
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #D5DFE9;
  box-shadow: transparent;
  padding-right: 12px;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none;
  padding-right: 12px;
}

.was-validated .form-control:valid, .form-control.is-valid {
  padding-right: 12px !important;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  padding-right: 12px !important;
  border: 1px solid var(--danger);
}
</style>