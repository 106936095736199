<template>
  <b-modal id="modal-tutorial" title="BootstrapVue" size="lg" hide-footer no-close-on-esc no-close-on-backdrop centered>
    <template #modal-header="{ close }">
      <div></div>
      <i class="fas fa-times hover-icon action-button" @click="closePopup"/>
    </template>
    <div>
      <div class="box-content-tutorial">
        <img v-if="$i18n.locale === 'th'" src="@/assets/images/upgrade/popup_tutorial.png" alt="popup-tutorial">
        <img v-else src="@/assets/images/upgrade/popup_tutorial_en.png" alt="popup-tutorial">
      </div>
      <div class="text-center mt-3">
        <button type="button" class="btn waves-effect waves-light btn-gradient"
                style="width: 160px; padding: 6px 12px; margin-bottom: 21px" @click="acceptTutorial()">
          {{ $t('common.accept') }}
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import functionsCommon from "@/common/functions";

export default {
  name: "modalTutorial",
  data() {
    return {}
  },
  computed: {
    userInfo () {
      return  this.$store.getters["profile/getProfile"]
    }
  },
  created() {
    if (this.userInfo?.identity_verify_status === 'verified') {
      this.setCookie('ACCEPT_TUTORIAL', true, 1)
    }
  },
  methods: {
    acceptTutorial() {
      this.setCookie('ACCEPT_TUTORIAL', true, 1)
      this.$bvModal.hide('modal-tutorial')
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "==" + (value || "") + expires + "; path=/";
    },
    closePopup () {
      this.$bvModal.hide('modal-tutorial')
    }
  }
}
</script>

<style>
.box-content-tutorial > img {
  object-fit: contain;
  width: -webkit-fill-available;
}
#modal-tutorial .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0!important;
}
#modal-tutorial .modal-header {
  padding: 1rem 1rem 0 1rem;
}
</style>